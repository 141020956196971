<script setup lang="ts">
const menu = ref<HTMLElement | null>(null)
const header = ref<HTMLElement | null>(null)
const route = useRoute()
const mobileNav = ref(false)
const windowWidth = ref('')

function toggleMenu () {
  if (menu.value?.classList.contains('closed')) {
    menu.value.classList.remove('closed')     
    menu.value.classList.add('opened')
    mobileNav.value = true
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
  } else {
    menu.value?.classList.remove('opened') 
    menu.value?.classList.add('closed')
    mobileNav.value = false
    document.body.style.position = ''
    document.body.style.top = ''
  }
}

function checkScreen() {
  windowWidth.value = `-${window.innerWidth}px`
  if (window.innerWidth >= 700) {
    mobileNav.value = false
    menu.value?.classList.remove('opened') 
    menu.value?.classList.add('closed')
  }
}

watch(
  () => route.path,
  () => {
    if (menu.value?.classList.contains('opened')) {
      menu.value.classList.remove('opened') 
      menu.value.classList.add('closed')
      mobileNav.value = false
      header.value!.style.position = ''
      document.body.style.position = ''
      document.body.style.top = ''     
    }
  }
)

onMounted(() => {
  window.addEventListener('resize', checkScreen)
  checkScreen()
  document.body.style.position = ''
  document.body.style.top = ''     
})
</script>

<template>
  <header 
    ref="header" 
    class="header"
  >
    <CommonLogo 
      width="85px"
      aspectRatio="3.2"
      logoFile="logo.svg"
    />
    <div class="header__discuss">
      <CommonDiscussProject />
    </div>
    <Transition name="mobile-nav">
      <div 
        v-if="mobileNav" 
        class="dropdown-nav"
      >
        <HeaderMenuMobile />
      </div>
    </Transition>
    <div class="header__menu-wrapper">
      <button 
        ref="menu"
        class="closed"
        @click="toggleMenu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button> 
    </div>
  </header>
</template>

<style lang='scss' scoped>
.header {
  --menu-gap: 61px;
  --menu-padding: 22px;
  padding-block: var(--menu-padding);
  display: none;
  align-items: center;
  background: var(--c-white);
  grid-template-columns: repeat(3, 1fr);

  @media (width < 1024px) {
    display: grid;
    width: 100%;
    z-index: 99;
    transition: .5s ease all;
  }

  &__discuss {
    --fd: column;
    --g: 0;
    --gtc: 56px;
    --w: 30px;
    --fz: 14px;
  }

  &__menu-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--c-bg-special);
    justify-content: center;
    align-items: center;
    display: none;

    @media (width < 1024px) {
      display: flex;
      margin-left: auto;
    }
  }
}

button {
  --size: 16px;
  height: 12px;
  width: var(--size);
  box-sizing: border-box;
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

span {
  height: calc(var(--size) / 8);
  --gap: calc(var(--size) / 24);
  border-radius: var(--gap);
  --n_gap: calc(-1 * var(--gap));
  width: 100%;
  display: block;
  background-color: black;
  position: absolute;
  transition:  transform 0.5s, opacity 0.5s;
  top: 50%;
  right: 0;
}

span:nth-child(2) {
  width: 80%;
}

.opened span:nth-child(1) {
  transform: rotate(45deg) translate(var(--n_gap), var(--n_gap));
}

.opened span:nth-child(2) {
  opacity: 0;
  transform: translate(0, var(--n_gap)) scale(0.01);
}

.opened span:nth-child(3) {
  transform: rotate(-45deg) translate(var(--gap), var(--n_gap));
}

.closed span:nth-child(1) {
  transform: translate(0, calc(-1 * var(--size) / 2 + var(--gap)));
}

.closed span:nth-child(2) {
  transform: translate(0, var(--n_gap));
  opacity: 1;
}

.closed span:nth-child(3) {
  transform: translate(0, calc(var(--size) / 2 - var(--gap) * 2 ));
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 87px;
  left: 0;
  width: 100%;
  max-width: v-bind(windowWidth);
  height: 100dvh;
  background: var(--c-white);
}
</style>

<style>
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: all .3s ease-out;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(v-bind(windowWidth));
}
</style>