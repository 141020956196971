<script setup lang="ts">
</script>

<template>
  <div class="menu">
    <div class="menu__top">
      <HeaderLangSwitch />
      <span class="menu__email">info@m3.sale</span>
    </div>
    <div class="menu__bottom">
      <ClientOnly>
        <nav
          class="nav"
          aria-label="main-navigation"
        >
          <ul
            role="list"
            class="nav__box"
          >
            <li 
              v-for="{ to, item } in menuMobileItems" 
              :key="to" 
              class="nav__item"
            >
              <NuxtLinkLocale 
                :to="to" 
                class="nav__link"
              >
                {{ $t(`header.${item}`) }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </nav>
      </ClientOnly>
      <div class="menu__social">
        <CommonSocial />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--c-bg-special);
  height: calc(100dvh - 80px);

  &__top {
    padding-block: 28px;
    background: var(--c-white);
    width: 100%;
    border-radius: 0 0 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__email {
    margin-block-start: 12px;
  }

  &__bottom {
    margin-block-start: 40px;
    padding-block: 40px;
    background: var(--c-white);
    width: 100%;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__social {
    color: var(--c-white)
  }
}

.nav {
  width: 100%;
  
  &__box {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__link {
    text-decoration: none;
    color: var(--c-text-primary);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
  }

  &__link.router-link-active {
    color: var(--c-brand);
  }
}
</style>