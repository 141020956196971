<script setup lang="ts">
interface AvailableLocales {
  [key: string]: any
}

const { locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const availableLocales = computed<AvailableLocales>(() => (locales.value))

</script>

<template>
  <div class="link-box">
    <NuxtLink
      v-for="locale in availableLocales"
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      class="link-box__link"
    >
      {{ locale.name }}
    </NuxtLink>
  </div>
</template>

<style lang='scss' scoped>
.link-box {
  display: flex;
  gap: 12px;

  &__link {
    color: var(--c-text-3);
    text-decoration: none;
  }

  &__link.router-link-active {
    color: var(--c-brand);
  }
}
</style>