<script setup lang="ts">
interface Props {
  bcg?: string
}

withDefaults(defineProps<Props>(), {
  bcg: 'var(--c-text-primary)',
})

const links = ref([
  { to: 'https://t.me/ivanproskura', iconName: 'IconTelegram' },
  { to: 'https://vk.com/m3sale', iconName: 'IconVk' },
])
</script>

<template>
  <div class="icons">
    <ClientOnly>
      <NuxtLink
        v-for="{to, iconName} in links"
        :key="to"
        :to="to"
        class="icons__icon"
      >
        <CommonIcon 
          :iconName="iconName"
          :bcg="bcg"
        />
      </NuxtLink>
    </ClientOnly>
  </div>
</template>

<style lang='scss' scoped>
.icons {
  display: flex;
  justify-content: var(--jc, flex-end);
  gap: 20px;
  margin-block: 52px 44px;

  @media (width < 700px) {
    margin-block: 0 24px;
  }

  &__icon {
    transition: color .2s;
    color: var(--c-white);
  }

  @media (any-hover: hover) {
    &__icon:hover {
      color: var(--c-brand);
    }
  }
}
</style>